import { Controller } from "@hotwired/stimulus"
import Noty from 'noty';

export default class extends Controller {
  static targets = [ "message" ]

  connect() {
    this.messageTargets.forEach(message => {
      this.show(message.dataset.flashText, message.dataset.flashType)
    });
  }

  show(text, type) {
    new Noty({
      text: `<span role="alert">${text}</div>`,
      type: type,
      theme: 'bkpan',
      timeout: 3500,
    }).show()
  }
}
