import { get } from "@rails/request.js"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["onPremiseDatesWrapper", "onPremiseDatesInput"]
  static values = {
    weekdaySchedulesUrl: String,
    flatpickrDateFrom: String,
    flatpickrDateTo: String,
    onPremise: Boolean
  }

  on_select(event) {
    const option = event.currentTarget.options[event.currentTarget.options.selectedIndex]
    if (option.dataset.onPremise === "true" && this.onPremiseValue) {
      this.update_flatpickr(option.value, this.onPremiseDatesInputTarget.flatpickr.fp)
      this.hide_flatpickr(false)
    } else {
      this.hide_flatpickr(true)
    }
  }

  hide_flatpickr(force) {
    this.onPremiseDatesWrapperTarget.classList.toggle("d-none", force)
    this.onPremiseDatesInputTarget.disabled = force
  }

  async update_flatpickr(location_id, flatpickr) {
    let url = this.weekdaySchedulesUrlValue.replace("ID", location_id)
    const response = await get(url, {
      query: {
        from: this.flatpickrDateFromValue,
        to: this.flatpickrDateToValue
      }
    })
    if (response.ok) {
      const data = await response.json
      const availableDates = data.map((d) => new Date(d))
      flatpickr.set("enable", availableDates)
      flatpickr.setDate(flatpickr.selectedDates.filter(item => availableDates.includes(item)))
    }
  }
}
