import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["label", "input"]

  connect() {
    this.inputTargets.forEach(input => {
      if (input.checked) {
        input.parentElement.classList.add('active');
      }
    });
  }

  select(event) {
    this.labelTargets.forEach(label => { label.classList.remove('active'); });
    event.target.parentElement.classList.add('active')
  }
}